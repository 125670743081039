import React, { useEffect, useState } from "react";
import blitz from "blitzllama-js";

import { getLandingPageData } from "../api/services/ai";
import { useLocation, useNavigate } from "react-router-dom";
import NewMediaPlayer from "../components/NewMediaPlayer";
import warningimg from "../assets/icons/alerttriangle.svg";
import openLink from "../assets/icons/videoguide.svg";


const AiFeedbackComponent = ({ landingPageData, handleNavigate }) => {
  return (
    <div className="space-y-6 w-full overflow-y-auto">
      {landingPageData &&
        landingPageData.ai_analysis_list &&
        landingPageData.ai_analysis_list.length > 0 &&
        landingPageData.ai_analysis_list.map((item, index) => (
          <div
            className={`py-5 px-8 ${item.status === "pending" || item.status === "error"
              ? "bg-grey1"
              : "bg-green2"
              } flex justify-between rounded-full w-full`}
            key={index}
            onClick={() => {
              item.status !== "pending" &&
                item.status !== "error" &&
                handleNavigate(item.prompt_uid);
            }}
          >
            <p className="text-white text-base font-sans font-semibold tracking-wide">
              {item.title}
            </p>
            {item.status === "pending" ? (
              <img
                className="h-5 w-5 animate-spin"
                src={require("../assets/icons/loader.png")}
                alt="loader"
              />
            ) : item.status === "error" ? (
              <img
                className="h-5 w-5"
                src={require("../assets/icons/warning.png")}
                alt="warning"
              />
            ) : (
              <img
                className="h-6 w-6"
                src={require("../assets/icons/arrow-right-outline-white.png")}
                alt="arrow-right"
              />
            )}
          </div>
        ))}
      <div className="h-[200px]"></div>
    </div>
  );
};

const AiRating = ({ type, rated, descr }) => {
  const ratings = [1, 2, 3, 4, 5, 6, 7, 8];

  return (
    <div className="w-72 rounded-[8px] w-full bg-gray-100">
      <div className="ml-[24px] h-[4px] w-[48px] bg-green1"></div>
      <div className="p-[24px]">
        <h2 className="text-2xl font-bold mb-[16px]">{type}</h2>
        <div>
          <div className="mb-[8px] text-gray-500 font-[550]">AI Rating</div>
          <div className="flex mb-[16px] space-x-0 font-bold w-full">
            {ratings.map((rating, index) => {
              const isFirst = index === 0;
              const isLast = index === ratings.length - 1;
              return (
                <div
                  key={rating}
                  className={`w-[12.5%] h-8 flex items-center justify-center ${rated === rating
                    ? "bg-green1 text-white"
                    : "text-[#44454E] bg-white border-[1px] border-r-0 border-[#D1EBDD] "
                    } ${isFirst ? "rounded-tl-lg rounded-bl-lg" : ""} ${isLast
                      ? " border-r-[1px] border-[#D1EBDD] rounded-tr-lg rounded-br-lg"
                      : ""
                    }`}
                >
                  {rating}
                </div>
              );
            })}
          </div>
        </div>

        <div>
          <div className="mb-[8px] text-gray-500 font-bold">AI Review</div>
          <div className="p-[16px] text-base bg-white border-[1px] border-[#D1EBDD] rounded-[8px] text-[#44454E]">
            <p>{descr}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const AiRatingComponent = ({ landingPageData, handleNavigate, indext }) => {
  const [reportType, setReportType] = useState(indext || 0);
  const [apiData, setApiData] = useState(null);

  return (
    <div className="">
      <div
        className={`flex flex-col space-y-3 ${window.innerHeight < 800 ? "h-[65vh]" : "h-[88vh]"
          }`}
      >
        {landingPageData && landingPageData.ai_rating && (
          <div className="flex w-full space-x-[16px] text-gray-500 h-[5vh] font-bold">
            <div
              className="w-1/2 cursor-pointer"
              onClick={() => {
                setReportType(0);
              }}
            >
              <div className={`${reportType ? "" : "text-green1"} mb-2.5`}>
                AI Assessment
              </div>
              <div
                className={`${reportType ? "bg-gray-200" : "bg-green1"
                  } h-1 w-full rounded-lg`}
              ></div>
            </div>
            <div
              className="w-1/2 cursor-pointer"
              onClick={() => setReportType(1)}
            >
              <div
                className={`${reportType === 0 ? "" : "text-green1"} mb-2.5`}
              >
                AI Feedback
              </div>
              <div
                className={`${reportType === 0 ? "bg-gray-200" : "bg-green1"
                  } h-1 w-full rounded-lg`}
              ></div>
            </div>
          </div>
        )}

        <div
          className={`flex flex-col h-[85%] ${window.innerHeight < 800 ? "h-[83%]" : "h-[65%]"
            } overflow-y-auto scrollbar-none`}
        >
          {reportType === 0 && landingPageData && landingPageData.ai_rating && (
            <div className="flex-3  space-y-[24px] relative z-10 pb-3">
              {landingPageData.ai_rating.map((rating, index) => (
                <AiRating
                  key={index}
                  type={rating.rubric_section}
                  rated={rating.score}
                  descr={rating.reason}
                />
              ))}
              <div className="h-[100px]"></div>
            </div>
          )}
          {((landingPageData && !landingPageData.ai_rating) ||
            reportType === 1) && (
              <div>
                <AiFeedbackComponent
                  landingPageData={landingPageData}
                  handleNavigate={handleNavigate}
                />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default function NewFeedback() {
  const navigate = useNavigate();
  const location = useLocation();

  const { indext = 0 } = location.state || {};

  const [landingPageData, setLandingPageData] = useState(null);

  const params = new URLSearchParams(window.location.search);
  const userId = params.get("userId");
  const email = params.get("email");
  localStorage.setItem("email", email);
  const assessmentId = params.get("assessmentId");
  const userType = params.get("userType") || params.get("usertype");
  const flow = params.get("flow");
  const [aiAnalysisStatus, setAiAnalysisStatus] = useState("");

  useEffect(() => {
    blitz.createUser(userId);
    return () => {
      blitz.logout();
    };
  }, [userId]);

  useEffect(() => {
    const hasPlayed = localStorage.getItem("PVPlayed");
    console.log("hasPlayed:", hasPlayed);
    if (hasPlayed === "true") {
      blitz.triggerEvent("pv_feedback");
      localStorage.removeItem("PVPlayed");
    }
  }, []);

  const handleCloseIconButtonClick = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        event: "navigateBack",
      })
    );
  };

  useEffect(() => {
    let intervalId;
    let pollingCount = 0;

    const fetchData = async () => {
      console.log(userId, assessmentId, userType);
      try {
        const landingPageJSONData = await getLandingPageData(
          userId,
          assessmentId,
          userType
        );
        setLandingPageData(landingPageJSONData);
        console.log(landingPageJSONData);

        const ai_status = landingPageJSONData?.ai_analysis_status;

        if (ai_status === "pending" || ai_status === "inprogress") {
          pollingCount++;
          if (pollingCount > 10) {
            navigate("/error");
            clearInterval(intervalId);
          }
          return;
        }

        if (
          ai_status === "verification_failed" ||
          ai_status === "short_recording"
        ) {
          navigate("/error", { state: { ai_status } });
          clearInterval(intervalId);
          return;
        }

        if (ai_status === "success") {
          clearInterval(intervalId);
        }
        setAiAnalysisStatus(ai_status);
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/error");
      }
    };

    fetchData();
    intervalId = setInterval(fetchData, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleNavigate = (promptUid) => {
    const url = `/ai-assessment/${promptUid}?userId=${userId}&assessmentId=${assessmentId}&userType=${userType}&flow=${flow}`;
    navigate(url);

    const postMessageJSON = {
      event: "trackMixpanelEvent",
      meta: { action: "" },
    };

    switch (promptUid) {
      case "suggested_speech_json":
        postMessageJSON["meta"]["action"] = "CLICKED_TRANSCRIPT_REVIEW";
        break;
      case "sentence_comparison_json":
        postMessageJSON["meta"]["action"] = "CLICKED_SENTENCE_CHECK";
        break;
      case "vocabulary_json":
        postMessageJSON["meta"]["action"] = "CLICKED_ARTICULATION";
        break;
      case "grammer_json":
        postMessageJSON["meta"]["action"] = "CLICKED_ACCURRACY_CHECK";
        break;
      default:
        break;
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(postMessageJSON));
  };

  const handleClickRetry = () => {
    window.location.href = `${process.env.REACT_APP_EXPERIENCE_URL}evaluate/situations`;
  };

  const navigateToContactUs = () => {
    window.open("https://vani.coach/contactUs.html");
  };

  const handleClickEnrollNow = async () => {
    navigate("/program-overview");
  };

  const handlePrimaryButtonClick = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        event: "trackMixpanelEvent",
        meta: { action: "PRACTICE_AGAIN_AI_FEEDBACK" },
      })
    );
    const situation = landingPageData?.situation;
    if (situation.id) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          event: "navigateToPreviewSituation",
          meta: { situationId: situation.id },
        })
      );
    } else {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          event: "navigateToSelectSituation",
        })
      );
    }
  };

  return (
    <div className="max-h-[100vh] overflow-hidden">
      <div className="flex flex-col items-center justify-start w-full h-dvh px-[32px] pt-[32px] py-10 mt-ios">
        <div className="flex items-center justify-between w-full">
          <div className="relative flex align-middle justify-center w-full">
            <h1 className="text-2xl font-semibold text-darkgray font-sans">
              AI Report
            </h1>
            {userType === "learner" && (
              <img
                className="h-9 w-9 absolute left-0 top-0"
                alt="cross"
                src={require("../assets/icons/cross-outlined-grey.png")}
                onClick={handleCloseIconButtonClick}
              />
            )}
          </div>
        </div>
        {landingPageData &&
          landingPageData.situation &&
          landingPageData.situation.title && (
            <p className="text-[#82858F] font-sans text-sm mt-6">
              {landingPageData.situation.title}
            </p>
          )}
        {landingPageData && landingPageData.audio_url && (
          <div className="mt-6 w-full">
            <NewMediaPlayer url={landingPageData.audio_url} />
          </div>
        )}

        {(aiAnalysisStatus === "content_policy_triggered") && (
          <div className="mt-8">
            <div className="flex flex-col space-y-3 h-screen bg-white">
              {/* Title with Image */}
              <div className="flex items-center text-xl font-bold text-orange-600 gap-2">
                <p>
                  We Couldn’t Generate AI Feedback due to Policy Restrictions
                  <img src={warningimg} className="h-7 w-7 inline-flex ml-1" alt="warning icon" />
                </p>
              </div>

              {/* List Section */}
              <div>
                <ol className="list-disc list-outside text-base text-darkBlue3 font-sans font-pt-sans leading-6 pl-4">
                  <li >
                    Your recording was flagged by
                    <a
                      href="https://learn.microsoft.com/en-us/azure/ai-services/openai/concepts/content-filter?tabs=warning%2Cuser-prompt%2Cpython-new"
                      target="_blank"
                      className="font-bold underline text-[14px] items-center ml-1"
                    >
                      Azure’s Content Policy
                      <img src={openLink} className="h-4 w-4 inline-flex ml-1" alt="open link icon" />
                    </a>. Content related to hate, religion, self-harm, or violence aren’t assessed due to AI safety policies.
                  </li>
                  <li>Filtering happens automatically and at random to ensure a safe and constructive space—it’s not a personal decision.</li>
                  <li>Try rewording your practice and submit.</li>
                </ol>
              </div>
            </div>
          </div>
        )}

        {aiAnalysisStatus !== "junk_speech" && aiAnalysisStatus !== "error" && (
          <div className="space-y-6 w-full mt-6">
            <AiRatingComponent
              landingPageData={landingPageData}
              handleNavigate={handleNavigate}
              indext={indext}
            />
          </div>
        )}

        {(aiAnalysisStatus === "junk_speech" ||
          aiAnalysisStatus === "error") && (
            <div className="mt-[32px]">
              <div className="flex flex-col space-y-[12px]  h-screen bg-white">
                <div className="text-xl font-bold text-orange-600 inline-flex items-center">
                  We couldn't generate feedback
                  <img
                    src={warningimg}
                    className="h-[20px] ml-2"
                    alt="warning icon"
                  />
                </div>

                <div className="">
                  <p className="text-base font-bold leading-6 text-darkgray">
                    Please try the following:
                  </p>
                  <ol className="list-decimal list-inside text-base text-darkBlue3 font-sans font-pt-sans leading-6">
                    <li>
                      Ensure MS Teams, Google Meet or other apps aren’t using the
                      microphone.
                    </li>
                    <li>Record in a quieter environment.</li>
                    <li>Include more relevant details to your scenario.</li>
                  </ol>
                </div>
                <div>
                  <p className="text-darkBlue3 font-pt-sans text-base font-bold leading-[24px]">
                    If the issue persists, contact{" "}
                    <a
                      href="mailto:care@vani.coach"
                      className="text-primary no-underline"
                    >
                      care@vani.coach
                    </a>
                  </p>
                </div>
              </div>
            </div>
          )}

        {flow === "evaluate" && (
          <div className="z-10 w-full flex items-end justify-center absolute bottom-0">
            <div className="flex justify-around m-8 w-full">
              <button
                className="py-4 mr-2 rounded-full bg-white text-darkgray hover:bg-gray-300 border border-darkgray font-semibold flex-1 h-14"
                onClick={() => handleClickRetry()}
              >
                Retry
              </button>
              <button
                className="py-4 ml-2 rounded-full bg-orange1 text-white hover:bg-orange-700 font-semibold flex-1 h-14"
                onClick={() => handleClickEnrollNow()}
              >
                Learn More
              </button>
            </div>
          </div>
        )}
        {flow === "experience" && (
          <div className="w-full p-6 absolute bottom-0 rounded-t-2xl z-10 h-[6rem] bg-white">
            <button
              className="font-sans flex justify-center bg-primary text-white font-semibold py-4 px-4 rounded-[30px] w-full tracking-wide"
              onClick={navigateToContactUs}
            >
              Contact Us
              <span>
                <img
                  className="ml-2 h-6 w-6"
                  src={require("../assets/icons/arrow-right-outline-white.png")}
                  alt="arrow-right"
                />
              </span>
            </button>
          </div>
        )}
        {flow === "learner" && (
          <div className="w-full p-6 absolute bottom-0 rounded-t-2xl z-10 h-[6rem] bg-white">
            <button
              className="font-sans flex justify-center bg-primary text-white font-semibold py-4 px-4 rounded-[30px] w-full tracking-wide"
              onClick={handlePrimaryButtonClick}
            >
              Let's Practice Again!
              <span>
                <img
                  className="ml-2 h-6 w-6"
                  src={require("../assets/icons/arrow-right-outline-white.png")}
                  alt="arrow-right"
                />
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
